import http from "@/http";
import './index.less'

export default {

    data() {
        return {

            loading: true,
            list: [],
            pagination: {

                showSizeChanger: false, current: 1,
                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} itens`,
                onChange: (page) => this.pagination.current = page
            }
        }   
    },

    created() {

        this.loading = true;
        this.load();
    },
    computed: {

        session() {

            return this.$store.getters['session/user'];
        }
    },
    methods: {

        load() {

            http.get('painel/vistorias.listarFotos', { params: { vistoria_id:  this.$route.params.id } }).then(({ data }) => {

                this.list = data

            }).finally(() => this.loading = false).catch(err => {

                console.log(err);
                this.$error({

                    title: 'Desculpe, houve um erro!',
                    content: 'Tente novamente mais tarde.',
                });
            });
        },
        windowOpen(url) {

            if (!window.open(url, '_blank')) {

                this.$warning({
                    title: 'Ops, o navegador está bloqueando popups.',
                    content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p></div>
                })
            }
        },
        downloadFotos(id) {

            if (!id) return;

            this.loading = true;

            http.get('/painel/vistorias.downloads', { params: { id: id }, responseType: 'blob' }).then(data => {

                const url = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
                this.windowOpen(url);

            }).finally(() => this.loading = false).catch(err => {

                const reader = new FileReader;
                reader.onload = () => {

                    const error = JSON.parse(reader.result);
                    this.$error({
                        title: 'Desculpe, houve um erro ao recuperar o laudo.',
                        content: error.message
                    });
                }
                reader.readAsText(err);
            });
        },
        onClickView(fileName){

            if(!fileName) return;

            // const uuid = fileName.split('.')[0];

            this.$router.push({ name: 'visualizacao', params: { id: this.$route.params.id, uuid: fileName }})
        },
        goBack(){

            this.$router.push({name: this.$route?.query.back ?? 'main'});
        }
    },
    render(){

        return (
            <div class="view view-body-scrollable">
                <div class="view-header">
                    <div class="view-header-body" style='padding: 16px 12px'>
                        <div class="view-header-start">
                            <g-icon icon={['fas', 'photo-video']} />
                            <h1 style='font-size: 26px'>Galeria de fotos e vídeos da vistoria</h1>
                        </div>
                        <div class="view-header-end">
                            <a-tooltip title="Download das fotos">
                                <a-button type="primary" onClick={() => this.downloadFotos(this.$route.params.id)} disabled={!this.list.length}>
                                    <g-icon icon={['fas', 'download']} fixedWidth />
                                </a-button>
                            </a-tooltip>
                            <a-button onClick={this.goBack} style='margin-left: 8px;'>
                                <g-icon icon={['fas', 'arrow-left']} fixedWidth/>
                                <span>Voltar</span>
                            </a-button>
                        </div>
                    </div>
                </div>
            
                <div class='gallery' style='overflow: hidden'>
                    <a-list
                        grid={{ gutter: 16, column: 6 }}
                        dataSource={this.list}
                        loading={this.loading}
                        pagination={this.pagination}
                        renderItem={(item, index) => {

                            return (

                                <a-list-item>
                                    <a-card hoverable onClick={() => this.onClickView(item.arquivo)}>
                                        <div
                                            class='thumbnail'
                                            slot='cover'
                                            style={
                                                {
                                                    'background-image': `url(${item.thumb})`,
                                                    'display': 'flex',
                                                    'align-items': 'center',
                                                    'justify-content': 'center'
                                                }
                                            }
                                        >
                                            {
                                                !!~['mp4', 'webm', 'wmv', 'mov'].indexOf(item.extensao) && (
                                                    <i style='color: #FFF; font-size: 42px; opacity: 0.6;'><g-icon icon={['fas', 'play-circle']} /></i>
                                                )
                                            }
                                        </div>
                                        <a-card-meta title={item.legenda} />
                                    </a-card>
                                </a-list-item>
                            )
                        }}
                    />
                </div>
            </div>
        )
    }
}
